//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-816:_8604,_9052,_9792,_4952,_9528,_7564,_8964,_6456;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-816')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-816', "_8604,_9052,_9792,_4952,_9528,_7564,_8964,_6456");
        }
      }catch (ex) {
        console.error(ex);
      }